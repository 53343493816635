/**
 * @author Vivek Singh
 * @since 02-01-2024
 * @description to handle all network apis
 * @returns object of network apis
 */

export const APIUrl = "https://tradingapi.kickrtech.in/api/v1/user";
// export const APIUrl = "https://tradingapi.kickrtechnology.in/api/v1/user";
export const NetworkConfiguration = {
  GET_USER_PROFILE: "/userProfile",
  LOGIN: "/login",
  KYC_REGISTRATION: "/uploadDocs",
  REGISTER_USER: "/referralRegistration",
  CHANGE_PASSWORD: "/changePassword",
  UPDATE_PROFILE: "/profile",
  STATE_CITY_BY_PIN: "/pinCode",
  SEND_OTP_TO_MAIL_FOR_FORGET: "/sendOtpForForget",
  FORGET_PASSWORD: "/forgetPassword",

  GET_DIRECT_NETWORKS: "/direct",
  GET_DOWNLINE_NETWORK: "/downline",

  GET_ACTIVE_CONTRACTS: "/purchasedContract",
  PURCHASE_NEW_CONTRACTS: "/purchaseNewContract",
  GET_CONTRACT_LIST: "/contract",

  GET_STB_INCOME: "/stbIncome",
  GET_DIRECT_INCOME: "/directIncome",
  GET_DOWNLINE_INCOME: "/downlineIncome",

  REFERRAL_VERIFICATION: "/referralVerification",

  SEND_OTP_TO_MAIL: "/sendMail",
  VERIFY_MAIL_OTP: "/verifyMailOTP",

  SEND_OTP_TO_PHONE: "/sendOtp",
  VERIFY_PHONE_OTP: "/verifyOtp",

  SUPPORT_CATEGORY: "/category",
  RAISE_TICKET: "/ticket",
  GET_TICKETS: "/getAllTickets",
  GET_TICKET_BY_ID: "/ticket",
  UPDATE_TICKET: "/update",

  GET_EARNING_LIST: "/earningWallet",
  WITHDRAW_BALANCE: "/sendWithdrawlRequest",
  WITHDRAWALS_LIST: "/getWithdrawalRequest",

  GET_REWARDS: "/rewardsListing",

  ADD_GENERATION: "/addGenPercent",

  RENEW_CONTRACT: "/renewContract",

  GET_GRAPH_DATA: "/dashEarning",

  RE_APPLY_KYC: "/reApply",

  CLAIM_REWARD: "/claimRewards",

  FIRST_TIME_PASSWORD: "/firstTimeChangePassword",
  NOTIFICATION: "/notification",

  VERIFY_MEMBERSHIP: "/verifyMemberShip",

  GET_USER_EARNED_REWARDS: "/rewardIncome",
};
